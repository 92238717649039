import { QaroniEnumInformation } from '@qaroni-core/types/qaroni-enum-information/qaroni-enum-information';

export enum UserRoleEnum {
  CLIENT = 'CLIENT',
  MERCHANT = 'MERCHANT',
  TOWN_HALL = 'TOWN_HALL',
}

export const UserRoleArray = [
  UserRoleEnum.CLIENT,
  UserRoleEnum.MERCHANT,
  UserRoleEnum.TOWN_HALL,
];

export const UserRoleInfo: QaroniEnumInformation<UserRoleEnum> = {
  [UserRoleEnum.CLIENT]: {
    name: 'Client',
  },
  [UserRoleEnum.MERCHANT]: {
    name: 'Merchant',
  },
  [UserRoleEnum.TOWN_HALL]: {
    name: 'Town hall',
  },
};
